<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="108px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item label="项目" prop="projectId">
                        <project-select v-model="formData.projectId">
                        </project-select>
                    </el-form-item>
                    <el-form-item prop="name" label="名称">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="remark" label="备注">
                        <el-input v-model="formData.remark"></el-input>
                    </el-form-item>
                    <el-form-item prop="needGas" label="需要支付GAS">
                        <el-switch v-model="formData.needGas"></el-switch>
                    </el-form-item>
                    <el-form-item prop="limited" label="指定藏品">
                        <el-switch v-model="formData.limited"></el-switch>
                    </el-form-item>
                    <el-form-item prop="collectionIds" label="藏品" v-if="formData.limited">
                        <el-table :data="collections">
                            <el-table-column type="index" label="#"></el-table-column>
                            <el-table-column prop="id" label="ID"></el-table-column>
                            <el-table-column prop="name" label="名称"></el-table-column>
                            <el-table-column width="80" align="center">
                                <template v-slot="{ row, $index }">
                                    <el-button type="danger" plain @click="delCollection($index)" size="mini">
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button @click="addCollection" size="mini">添加</el-button>
                    </el-form-item>
                    <el-form-item prop="expiration" label="到期时间">
                        <el-date-picker
                            v-model="formData.expiration"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-dialog title="添加藏品" :visible.sync="showCollectionDialog" width="500px">
                <el-form :model="{ collectionId }" ref="collectionForm" inline>
                    <el-form-item prop="collectionId" :rules="{ required: true, message: '请选择藏品' }">
                        <collection-search ref="collectionSearch" v-model="collectionId" :projectId="formData.projectId"> </collection-search>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button @click="showCollectionDialog = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="saveCollection">确定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CouponEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('coupon/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                    if (res.collectionIds) {
                        this.collections = res.collections;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {
                projectId: this.$store.state.projectId,
                limited: true,
                needGas: true
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                limited: [
                    {
                        required: true,
                        message: '请输入指定藏品',
                        trigger: 'blur'
                    }
                ],
                needGas: [
                    {
                        required: true,
                        message: '请输入需要支付GAS',
                        trigger: 'blur'
                    }
                ],
                expiration: [
                    {
                        required: true,
                        message: '请输入到期时间',
                        trigger: 'blur'
                    }
                ],
                collectionIds: [
                    {
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback();
                            } else {
                                callback(new Error('请添加藏品'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                projectId: [{ required: true, message: '请选择项目' }]
            },
            collections: [],
            showCollectionDialog: false,
            collectionId: null
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };
            data.collections = null;

            this.saving = true;
            this.$http
                .post('/coupon/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/coupon/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        },
        addCollection() {
            this.collectionId = null;
            this.showCollectionDialog = true;
            if (this.$refs.collectionForm) {
                this.$nextTick(() => {
                    this.$refs.collectionForm.clearValidate();
                });
            }
        },
        delCollection(index) {
            this.collections.splice(index, 1);
        },
        saveCollection() {
            this.$refs.collectionForm
                .validate()
                .then(() => {
                    if (!this.collections.find(i => i.id === this.collectionId)) {
                        this.collections.push(this.$refs.collectionSearch.selected);
                        this.showCollectionDialog = false;
                    } else {
                        this.$message.error('请勿重复添加');
                    }
                })
                .catch(() => {});
        }
    },
    watch: {
        collections(val) {
            this.$set(
                this.formData,
                'collectionIds',
                val.map(i => i.id)
            );
        }
    }
};
</script>
<style lang="less" scoped></style>
